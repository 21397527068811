<template>
  <div>
    <div class="maintitle">商品订单列表</div>
    <el-divider></el-divider>
    <el-table :data="list" border>
      <el-table-column align="center" prop="name" label="商品名称"></el-table-column>
      <el-table-column align="center" prop="jifen" label="需要积分数"></el-table-column>
      <el-table-column align="center" prop="name" label="收货人"></el-table-column>
      <el-table-column align="center" prop="tel" label="联系电话"></el-table-column>
      <el-table-column align="center" prop="address" label="收货地址"></el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <span v-if='scope.row.status==1'>待发货</span>
          <span v-if='scope.row.status==2'>已发货</span>
          <span v-if='scope.row.status==3'>已完成</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button v-if='scope.row.status==1' size="small" @click="fahuo(scope,2)">发货</el-button>
            <el-button v-if='scope.row.status==1' size="small" @click="fahuo(scope,0)">拒绝发货</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      total: 0,
      page: 1
    };
  },
  computed: {},
  components: {},
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/jiameng/jifengoods/getGoodsOrder",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    fahuo(scope,status) {
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/jifengoods/setOrder",
        data: { id: scope.row.id,status:status }
      }).then(res => {
        if (res.data.status == 1) {
          if(status == 0){
            this.list.splice(scope.$index,1);
          }else{
            scope.row.status = status;
          }
        }
      });
    },
  },
  created: function() {
    this.getlist();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>